import { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  StudentAssignmentCubitProvider,
  useStudentAssignmentCubit,
} from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { StudentAssignmentEnroll } from './StudentAssignmentEnrollment'
import { StudentAssignmentHeader } from './StudentAssignmentHeader'
import {
  StudentAssignmentInfo,
  StudentAssignmentInfoLoading,
} from './StudentAssignmentInfo'
import {
  StudentAssignmentSteps,
  StudentAssignmentStepsLoading,
} from './StudentAssignmentSteps'
import { StudentAssignmentJoinSessionDialog } from './dialogs/JoinSessionDialog'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { CircleAlert } from 'components/icons/CircleAlert'
import { useTranslation } from 'react-i18next'
import { useBreakoutUser } from 'hooks/profile'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { BrainIcon } from 'components/icons/BrainIcon'
import { ArrowRepeatIcon } from 'components/icons/ArrowRepeat'
import { toast } from 'react-hot-toast'
import { StudentAssignmentInFuture } from './StudentAssignmentInFuture'
import { MAX_TIMEOUT } from 'util/timeout'
import { StudentAssignmentLibraryActionParser } from './StudentAssignmentLibraryActionParser'

export const StudentAssignmentPage = observer(function StudentAssignmentPage({
  assignmentId,
  sectionId,
  roomStateId,
}: {
  assignmentId: string
  sectionId: string
  roomStateId: string | undefined
}) {
  const { showDialog, dialogStore } = useDialogs()
  const store = useRootStore()
  const repository = useRepository()

  const cubit = useCubitBuilder(
    () =>
      new StudentAssignmentCubit(repository, {
        sectionId,
        assignmentId,
        roomStateId,
      }),
    [repository]
  )

  useEffect(() => {
    // if the roomStateId changes, change the url
    return reaction(
      () => cubit.roomStateId,
      (roomStateId) => {
        const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
        store.navigateTo(route, {
          assignmentId: cubit.assignmentId,
          sectionId: cubit.sectionId,
          roomStateId: roomStateId,
        })
      }
    )
  }, [cubit, store])

  useEffect(() => {
    const disposeJoinDialogReaction = reaction(
      () => ({
        canPop: cubit.canPopJoinDialog && dialogStore.dialogs.length === 0,
      }),
      ({ canPop }, prev) => {
        if (!canPop || prev?.canPop === canPop) return
        showDialog(() => <StudentAssignmentJoinSessionDialog cubit={cubit} />, {
          namespace: 'join-session',
          ignoreOnNamespace: true,
        })
      },
      { fireImmediately: true }
    )
    return () => {
      disposeJoinDialogReaction()
    }
  }, [dialogStore, cubit, showDialog])

  return (
    <StudentAssignmentCubitProvider value={cubit}>
      <div
        className="z-50 h-full  p-5 md:p-[2.5rem]"
        data-testid="student-assignment-page"
      >
        <div className="items-left flex h-full flex-col align-middle">
          <StudentAssignmentHeader />
          <div className="mt-2 grid flex-grow rounded-3xl bg-surface p-5 md:overflow-auto lg:overflow-hidden">
            <div className="flex h-full max-h-full flex-col-reverse items-center lg:flex-row lg:overflow-hidden">
              <StudentAssignmentView />
            </div>
          </div>
        </div>
        <AdminOnlyActions />
      </div>
      <StudentAssignmentLibraryActionParser />
    </StudentAssignmentCubitProvider>
  )
})

const StudentAssignmentView = observer(function StudentAssignmentView() {
  const {
    isReady,
    libraryObject: { requiresPayment, libraryObjectState },
    assignment: { assignedAtIsInFuture, assignedAt },
  } = useStudentAssignmentCubit()

  const [inFuture, setInFuture] = useState(assignedAtIsInFuture)

  useEffect(() => {
    setInFuture(assignedAtIsInFuture)
  }, [assignedAtIsInFuture])

  const isExpired = libraryObjectState === LibraryObjectState.expired

  const AssignmentInfoView = isReady ? (
    <StudentAssignmentInfo />
  ) : (
    <StudentAssignmentInfoLoading />
  )

  const AssignmentMainView = useMemo(() => {
    if (!isReady) return <StudentAssignmentStepsLoading />
    if (requiresPayment) return <StudentAssignmentEnroll />
    if (inFuture) return <StudentAssignmentInFuture />
    if (isExpired) return <ExpiredView />
    return <StudentAssignmentSteps />
  }, [isReady, requiresPayment, inFuture, isExpired])

  useEffect(() => {
    if (!assignedAtIsInFuture || !assignedAt) {
      return
    }

    const msUntilAssignedAt = assignedAt.getTime() - Date.now()

    if (msUntilAssignedAt >= MAX_TIMEOUT) {
      return
    }

    // Set a timeout rerender when the assignment is no longer in the future.
    const timeout = setTimeout(() => {
      setInFuture(false)
    }, msUntilAssignedAt)

    return () => clearTimeout(timeout)
  }, [assignedAtIsInFuture, assignedAt])

  return (
    <>
      {AssignmentInfoView}
      {AssignmentMainView}
    </>
  )
})

const ExpiredView = observer(function ExpiredView() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-3 rounded-3xl bg-core-tertiary px-9 py-5">
      <CircleAlert size={20} className="stroke-breakout-red" />
      <h3 className="text-title-medium">
        {t('student_assignment.assignment_expired')}
      </h3>
      <p className="text-body-medium max-w-[350px]">
        {t('student_assignment.assignment_expired_description')}
      </p>
    </div>
  )
})

const AdminOnlyActions = observer(function AdminOnlyActions() {
  const { isCorre } = useBreakoutUser()
  const cubit = useStudentAssignmentCubit()
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`student_assignment.${key}`),
    [t]
  )

  const [aiLoading, setAILoading] = useState(false)
  const [resetLoading, setResetLoading] = useState(false)

  if (!isCorre) {
    return null
  }
  return (
    <FloatingIconActionButton
      className="!absolute bottom-20 right-20 flex w-10 flex-col items-end"
      buttonClassName="bg-surface"
      kind="tertiary"
      Icon={AdminPanelSettingsIcon}
      aria-label={t('buttons.admin_actions')}
      actions={
        cubit.roomStateId
          ? [
              {
                text: tScoped('process_ai_again'),
                onClick: async () => {
                  setAILoading(true)
                  try {
                    const success = await cubit.startAIProcessing()
                    toast(
                      tScoped(
                        success
                          ? 'ai_processing_started'
                          : 'ai_processing_running'
                      )
                    )
                  } finally {
                    setAILoading(false)
                  }
                },
                Icon: BrainIcon,
                isLoading: aiLoading,
              },
              {
                text: tScoped('restart_case_study'),
                onClick: async () => {
                  setResetLoading(true)
                  try {
                    await cubit.resetRoomState()
                  } finally {
                    setResetLoading(false)
                  }
                },
                Icon: ArrowRepeatIcon,
                isLoading: resetLoading,
              },
            ]
          : []
      }
    />
  )
})
