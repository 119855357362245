import { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { SlideProjector } from 'components/SlideProjector'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  InstructorAssignmentCubitProvider,
  useInstructorAssignmentCubit,
} from 'hooks/cubits/instructorAssignment'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { Contents, MainPane } from 'pages/layout/TwoColumn'
import { SummaryView } from './SummaryView'
import { MaterialsView } from './materials/MaterialsView'
import { InstructorAssignmentResults } from './results/InstructorAssignmentResults'
import { PageHeader } from './results/PageHeader'
import { InstructorAssignmentStudents } from './students/InstructorAssignmentStudents'

export function InstructorAssignmentPage({
  sectionId,
  assignmentId,
}: {
  sectionId: string
  assignmentId: string
}) {
  const repository = useRepository()
  const user = useBreakoutUser()

  const cubit = useCubitBuilder(() => {
    return new InstructorAssignmentCubit(repository, {
      sectionId,
      assignmentId,
    })
  }, [repository, sectionId, assignmentId, user])

  return (
    <InstructorAssignmentCubitProvider value={cubit}>
      <MainPane className="z-20">
        <PageHeader />
        <Contents className="h-full">
          <PageWithTabs />
        </Contents>
      </MainPane>
    </InstructorAssignmentCubitProvider>
  )
}

const PageWithTabs = observer(function PageWithTabs() {
  const cubit = useInstructorAssignmentCubit()
  return (
    <div className="flex h-full flex-col">
      <div className="mb-5 flex flex-row gap-5">
        <BreakoutButton
          size="medium"
          data-testid="summary-tab-button"
          kind={cubit.selectedTab === 'summary' ? 'secondary' : 'tertiary'}
          onClick={() => cubit.changeTab('summary')}
        >
          Summary
        </BreakoutButton>
        <BreakoutButton
          size="medium"
          data-testid="results-tab-button"
          kind={cubit.selectedTab === 'results' ? 'secondary' : 'tertiary'}
          onClick={() => cubit.changeTab('results')}
        >
          Results
        </BreakoutButton>
        <BreakoutButton
          size="medium"
          data-testid="students-tab-button"
          kind={cubit.selectedTab === 'students' ? 'secondary' : 'tertiary'}
          onClick={() => cubit.changeTab('students')}
        >
          Students
        </BreakoutButton>
        <BreakoutButton
          size="medium"
          data-testid="materials-tab-button"
          kind={cubit.selectedTab === 'materials' ? 'secondary' : 'tertiary'}
          onClick={() => cubit.changeTab('materials')}
        >
          Course Materials
        </BreakoutButton>
      </div>
      <PageContent />
    </div>
  )
})

const order = ['summary', 'results', 'students', 'materials']
const PageContent = observer(function PageContent() {
  const cubit = useInstructorAssignmentCubit()
  const index = order.indexOf(cubit.selectedTab)
  let component: React.ReactNode = null

  if (cubit.selectedTab === 'students') {
    component = <InstructorAssignmentStudents />
  }

  if (cubit.selectedTab === 'materials') {
    component = <MaterialsView />
  }

  if (cubit.selectedTab === 'summary') {
    component = <SummaryView cubit={cubit} />
  }

  if (cubit.selectedTab === 'results') {
    component = <InstructorAssignmentResults />
  }

  return (
    <div className="h-full w-full rounded-2xl md:overflow-hidden" tabIndex={0}>
      <SlideProjector index={index}>{component}</SlideProjector>
    </div>
  )
})
