import { useRepository } from './auth'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { UserContext } from './contexts'
import { observer } from 'mobx-react-lite'
import { FullPageSpinner } from 'components/Spinner'
import { useEffect } from 'react'

/// passedProfile can be used for testing purposes
export const UserContextProvider = observer(function BreakoutUser({
  children,
  passedUser,
}: {
  children: React.ReactNode
  passedUser?: BreakoutUser
}) {
  const repo = useRepository()
  const { breakoutUser, featureFlags } = repo

  const user = passedUser || breakoutUser

  // if user is instructor, check if they are an organization member
  useEffect(() => {
    if (
      !user ||
      user.isLoading ||
      !user.isInstructor ||
      user.isOrganizationMember.initialized ||
      !featureFlags.isLoaded
    ) {
      return
    }
    if (featureFlags.data.showOrganizationsUI) {
      user.startOrganizationStreamsIfNotRunning()
    }
  }, [
    user,
    user?.isLoading,
    user?.isInstructor,
    user?.isOrganizationMember.initialized,
    featureFlags.isLoaded,
    featureFlags.data.showOrganizationsUI,
  ])

  if (
    !user ||
    user.isLoading ||
    !user.hasData ||
    (user.isInstructor &&
      featureFlags.isLoaded &&
      featureFlags.data.showOrganizationsUI &&
      (user.isOrganizationMember.loading ||
        !user.isOrganizationMember.initialized))
  )
    return <FullPageSpinner />

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
})
