import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { DoubleCheckIcon } from 'components/icons/DoubleCheck'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ShowExperienceDialog } from './dialogs/ShowExperienceDialog'
import { SummaryDialog } from './dialogs/SummaryDialog'
import { useWalkthroughDialog } from './dialogs/WalkthroughDialog.hooks'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { StartSessionDialog } from './dialogs/StartSessionDialog'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { XSmallIcon } from 'components/icons/XSmall'
import { MeetingResultsDialog } from './dialogs/MeetingResultsDialog'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'

const ExpiredView = observer(function CompletedView() {
  const { t } = useTranslation()
  return (
    <div className={classNames(`mt-1 flex-grow-0 py-5 text-center`)}>
      <div className=" text-body-medium text-grey-text">
        {t('dart.experience')}
      </div>
      <h3 className="text-title-large mt-3">
        <XSmallIcon
          size={20}
          className="mr-1 inline-block align-top text-breakout-dark-green"
        />
        {t('dart.assignment_expired')}
      </h3>
    </div>
  )
})

const CompletedView = observer(function CompletedView() {
  const cubit = useStudentAssignmentCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  const summary = cubit.roomStateSummary
  const roomCompleted = cubit.roomState.isCompleted
  const hasRoomStartedAt = cubit.roomState.data.roomStartedAt !== undefined
  const newMeetingResultsEnabled =
    cubit.repository.featureFlags.data.sessionResultsUseNew
  const canShowNewResults =
    roomCompleted &&
    hasRoomStartedAt &&
    newMeetingResultsEnabled &&
    Boolean(cubit.roomStateId)
  return (
    <div className={classNames(`mt-1 flex-grow-0 py-5 text-center`)}>
      <div className=" text-body-medium text-grey-text">
        {t('dart.experience')}
      </div>
      <h3 className="text-title-large mt-3">
        <DoubleCheckIcon
          size={20}
          className="mr-1 inline-block align-top text-breakout-dark-green"
        />
        {t('dart.completed')}
      </h3>
      <div className="mt-6">
        <div className="flex flex-row justify-center">
          <BreakoutButton
            onClick={() => {
              showDialog(() => (
                <ShowExperienceDialog
                  slideDeckId={cubit.libraryObject.slideDeckId}
                />
              ))
            }}
            className="text-body-large mr-1 inline-block whitespace-nowrap rounded-2xl bg-core-secondary px-6 py-2 text-core-on-tertiary"
          >
            {t('student_assignment.view_experience')}
          </BreakoutButton>
          {(summary?.hasData || canShowNewResults) && (
            <BreakoutButton
              onClick={() => {
                showDialog(() => {
                  if (canShowNewResults)
                    return <MeetingResultsDialog roomId={cubit.roomStateId!} />

                  return (
                    <SummaryDialog
                      summary={summary.data}
                      roomStateName={cubit.roomState.roomStateName}
                      roomStateActiveSlideChangedAt={
                        cubit.roomState.data.activeSlideChangedAt || null
                      }
                    />
                  )
                })
              }}
              className="text-body-large ml-1 inline-block whitespace-nowrap rounded-2xl bg-core-secondary px-6 py-2 text-core-on-tertiary"
            >
              {t('student_assignment.view_summary')}
            </BreakoutButton>
          )}
        </div>
      </div>
    </div>
  )
})

export const StudentAssignmentStep3 = observer(
  function StudentAssignmentStep3() {
    const cubit = useStudentAssignmentCubit()

    const libraryObject = cubit.libraryObject
    const { t } = useTranslation()
    const { showDialog } = useDialogs()
    const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({
      roomId: cubit.roomStateId,
    })

    if (libraryObject.libraryObjectState === LibraryObjectState.completed) {
      return <CompletedView />
    }

    if (libraryObject.libraryObjectState === LibraryObjectState.expired) {
      return <ExpiredView />
    }

    const startable = libraryObject.currentUserCanStartRoom
    const joinable = libraryObject.currentUserCanJoinRoom

    const fadeOut =
      !cubit.allPreWorkQuestionsAnswered ||
      !cubit.isReady ||
      (!startable && !joinable)

    const tooltip = cubit.roomState.isScheduled
      ? t('student_assignment.join_disabled_starts_at', {
          startsAt: libraryObject.canJoinTimeFormatted,
        })
      : t('student_assignment.join_disabled_please_schedule')

    return (
      <div
        className={classNames(
          `pb-8 pt-4 text-center`,
          fadeOut ? 'opacity-30' : ''
        )}
      >
        <div className=" text-body-medium text-grey-text">Step 3</div>
        <h3 className="text-title-large mt-3">Join Session</h3>
        <p className="text-body-medium mt-1">
          {t('dart.join_session_description')}
        </p>
        <div className="mt-3">
          {!startable && !joinable && (
            <BreakoutTooltip content={tooltip}>
              <div>
                <BreakoutButton disabled>
                  {t('student_assignment.join_session')}
                </BreakoutButton>
              </div>
            </BreakoutTooltip>
          )}
          {joinable && (
            <BreakoutButton
              disabled={!cubit.roomState.isScheduled || !cubit.roomStateId}
              onClick={async () => {
                if (!cubit.allPreWorkQuestionsAnswered) return
                if (!cubit.roomState.isScheduled) return

                showWalkthroughDialogIfNecessary(() => {
                  cubit.logEvent('room_join')
                  cubit.setJoiningSession()
                })
              }}
              className="text-label-large inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-core-on-primary disabled:opacity-50"
            >
              {t('student_assignment.join_session')}
            </BreakoutButton>
          )}
          {startable && (
            <BreakoutButton
              data-testid="start-session-button"
              disabled={!cubit.roomState.isScheduled || !cubit.roomStateId}
              onClick={async () => {
                if (!cubit.allPreWorkQuestionsAnswered) return
                if (!cubit.roomState.isScheduled) return
                if (cubit.roomStateId) {
                  showDialog(({ remove }) => (
                    <StartSessionDialog
                      onConfirmStartSession={async () => {
                        await cubit.startMeeting()
                        showWalkthroughDialogIfNecessary(() =>
                          cubit.setJoiningSession()
                        )
                        remove()
                      }}
                    />
                  ))
                }
              }}
              className="text-label-large inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-core-on-primary disabled:opacity-50"
            >
              {t('student_assignment.start_session')}
            </BreakoutButton>
          )}
        </div>
      </div>
    )
  }
)
