import { SlideDeckState } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import {
  AssignmentState,
  LibraryObjectState,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { XSmallIcon } from 'components/icons/XSmall'
import { useSettings } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

function PillDot({
  className = 'bg-fixed-grey',
  pulsing = false,
}: {
  className?: string
  pulsing?: boolean
}) {
  const { animationsEnabled } = useSettings()
  const shouldPulse = animationsEnabled && pulsing
  const animation = shouldPulse ? 'pulse-animation' : ''
  return (
    <span
      className={classNames(
        'inline-block h-[8px] w-[8px] rounded-full',
        className,
        animation
      )}
    ></span>
  )
}

export function BreakoutPill({
  icon,
  size = 'small',
  kind = 'secondary',
  dot,
  dotPulsing,
  dotClassName,
  label,
  chip,
  className,
  style,
  onClick,
  role,
  element,
  'aria-label': ariaLabel,
  'aria-live': ariaLive,
}: {
  kind?: 'primary' | 'secondary'
  size?: 'small' | 'large'
  icon?: React.ReactNode
  label: string
  dot?: boolean
  dotClassName?: string
  dotPulsing?: boolean
  chip?: boolean
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  role?: string
  element?: 'span' | 'button'
  'aria-label'?: string
  'aria-live'?: 'off' | 'assertive' | 'polite'
}) {
  // We're using strong because of a11y insanity
  const Element = element || 'strong'
  return (
    <Element
      aria-live={ariaLive}
      aria-label={ariaLabel}
      role={role}
      style={style}
      onClick={onClick}
      className={classNames(
        'text-label-medium inline-flex items-center justify-center gap-1 px-4 py-1',
        className,
        {
          'cursor-pointer': chip !== undefined,
          'bg-core-tertiary text-core-on-tertiary': kind === 'primary',
          'bg-core-secondary text-core-on-secondary': kind === 'secondary',
          'rounded-full': size === 'small',
          'h-[3.25rem] min-w-[9.5rem] rounded-2xl': size === 'large',
        }
      )}
    >
      {dot || dotClassName ? (
        <PillDot className={dotClassName} pulsing={dotPulsing} />
      ) : null}
      {icon ? <span className="inline">{icon}</span> : null}
      {label}
      {chip && (
        <button className="border-none" onClick={onClick}>
          <XSmallIcon size={10} />
        </button>
      )}
    </Element>
  )
}

BreakoutPill.LibraryObjectState = function BreakoutPillPrefab(
  props: PrefabPillProps & {
    state: LibraryObjectState
  }
) {
  switch (props.state) {
    case LibraryObjectState.live:
      return <BreakoutPill.Live {...props} />
    case LibraryObjectState.invited:
      return <BreakoutPill.Invited {...props} />
    case LibraryObjectState.mustSchedule:
      return <BreakoutPill.MustSchedule {...props} />
    case LibraryObjectState.completed:
      return <BreakoutPill.Completed {...props} />
    case LibraryObjectState.enrolled:
      return <BreakoutPill.Enrolled {...props} />
    case LibraryObjectState.expired:
      return <BreakoutPill.Expired {...props} />
    case LibraryObjectState.pending:
      return <BreakoutPill.Pending {...props} />
    case LibraryObjectState.readyToStart:
      return <BreakoutPill.ReadyToStart {...props} />
    case LibraryObjectState.scheduled:
      return <BreakoutPill.Scheduled {...props} />
  }
}
BreakoutPill.SectionState = function BreakoutPillPrefab(
  props: PrefabPillProps & {
    state: SectionState
  }
) {
  switch (props.state) {
    case SectionState.notStarted:
      return <BreakoutPill.NotStarted {...props} />
    case SectionState.completed:
      return <BreakoutPill.Completed {...props} />
    case SectionState.inProgress:
      return <BreakoutPill.InProgress {...props} />
  }
}

BreakoutPill.SlideDeckState = function BreakoutPillPrefab(
  props: PrefabPillProps & {
    state: SlideDeckState
  }
) {
  switch (props.state) {
    case SlideDeckState.deleted:
      return <BreakoutPill.Archived {...props} />
    case SlideDeckState.draft:
      return <BreakoutPill.Draft {...props} />
    case SlideDeckState.hidden:
      return <BreakoutPill.Archived {...props} />
    case SlideDeckState.published:
      return <BreakoutPill.Published {...props} />
    case SlideDeckState.uninitialized:
      return <BreakoutPill.Archived {...props} />
  }
}

BreakoutPill.AssignmentState = function BreakoutPillPrefab(
  props: PrefabPillProps & {
    state: AssignmentState
  }
) {
  switch (props.state) {
    case AssignmentState.pending:
      return <BreakoutPill.Pending {...props} />
    case AssignmentState.completed:
      return <BreakoutPill.Completed {...props} />
  }
}

type PrefabPillProps = {
  kind?: 'primary' | 'secondary'
  size?: 'small' | 'large'
  className?: string
  dotClassName?: string
}

BreakoutPill.Chips = observer(function BreakoutPillPrefab(
  props: PrefabPillProps & {
    kind?: 'primary' | 'secondary'
    labels: string[]
    onClick: (label: string) => void
  }
) {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {props.labels.map((label, index) => (
        <BreakoutPill.Chip
          kind={props.kind || 'secondary'}
          key={index}
          onClick={() => props.onClick(label)}
          label={label}
        />
      ))}
    </div>
  )
})

BreakoutPill.Chip = function BreakoutPillPrefab(
  props: PrefabPillProps & {
    label: string
    onClick: () => void
  }
) {
  return <BreakoutPill {...props} chip />
}

BreakoutPill.Live = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.live')}
      dotClassName={classNames('bg-fixed-vibrant-green', props.dotClassName)}
      dotPulsing
    />
  )
}

BreakoutPill.MustSchedule = function BreakoutPillPrefab(
  props: PrefabPillProps
) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.must_schedule')}
      dotClassName={classNames('bg-fixed-orange', props.dotClassName)}
    />
  )
}

BreakoutPill.Enrolled = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return <BreakoutPill {...props} label={t('pills.enrolled')} dot />
}

BreakoutPill.Pending = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.pending')}
      dotClassName={classNames('bg-fixed-orange', props.dotClassName)}
    />
  )
}

BreakoutPill.ReadyToStart = function BreakoutPillPrefab(
  props: PrefabPillProps
) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.ready_to_start')}
      dotClassName={classNames('bg-fixed-vibrant-green', props.dotClassName)}
      dotPulsing
    />
  )
}

BreakoutPill.Scheduled = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.scheduled')}
      dotClassName={classNames('bg-fixed-orange', props.dotClassName)}
    />
  )
}

BreakoutPill.Invited = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.invited')}
      dotClassName={classNames('bg-fixed-grey', props.dotClassName)}
    />
  )
}

BreakoutPill.NotStarted = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.not_started')}
      dotClassName={classNames('bg-fixed-grey', props.dotClassName)}
    />
  )
}

BreakoutPill.InProgress = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.in_progress')}
      dotClassName={classNames('bg-fixed-orange', props.dotClassName)}
    />
  )
}

BreakoutPill.Completed = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.completed')}
      dotClassName={classNames('bg-core-success', props.dotClassName)}
    />
  )
}

BreakoutPill.Expired = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.expired')}
      dotClassName={classNames('bg-core-error', props.dotClassName)}
    />
  )
}

BreakoutPill.Abandoned = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.abandoned')}
      dotClassName={classNames('bg-core-error', props.dotClassName)}
    />
  )
}

BreakoutPill.Draft = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.draft')}
      dotClassName={classNames('bg-fixed-orange', props.dotClassName)}
    />
  )
}

BreakoutPill.Archived = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.archived')}
      dotClassName={classNames('bg-core-error', props.dotClassName)}
    />
  )
}

BreakoutPill.Published = function BreakoutPillPrefab(props: PrefabPillProps) {
  const { t } = useTranslation()
  return (
    <BreakoutPill
      {...props}
      label={t('pills.published')}
      dotClassName={classNames('bg-core-success', props.dotClassName)}
    />
  )
}
