import classNames from 'classnames'
import type { ChangeEvent, ForwardedRef } from 'react'
import { forwardRef, useCallback, useRef, useState } from 'react'
import { FormLabel } from './form/FormLabel'
import { FormError } from './form/FormError'
import type { FormFieldDefaults } from './types'
import { XSmallIcon } from 'components/icons/XSmall'

interface BreakoutInputProps extends FormFieldDefaults<'input'> {
  // secondary is the default 'kind' and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  LeadingIcon?: React.ComponentType<
    { size: number } & React.SVGProps<SVGSVGElement>
  >
  iconClassName?: string
  inputClassName?: string
  clearIcon?: boolean
  iconSize?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const BreakoutTextInput = forwardRef(function BreakoutTextInput(
  {
    disabled,
    kind = 'tertiary',
    name,
    label,
    labelClass,
    defaultValue,
    className,
    testId,
    LeadingIcon,
    iconSize = 18,
    iconClassName,
    required,
    placeholder,
    error,
    errorClass,
    inputClassName,
    clearIcon,
    ...rest
  }: BreakoutInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [showClearIcon, setShowClearIcon] = useState(false)
  let shouldShowClearIcon = showClearIcon

  const toggleClearIcon = useCallback(() => {
    if (inputRef.current) {
      if (inputRef.current.value) {
        setShowClearIcon(true)
      } else {
        setShowClearIcon(false)
      }
    }
  }, [])

  if (rest.value !== undefined && rest.value !== null && rest.value) {
    if (clearIcon) {
      shouldShowClearIcon = true
    } else {
      shouldShowClearIcon = false
    }
  }

  return (
    <div className={classNames('flex flex-col items-start gap-1', className)}>
      <FormLabel
        label={label}
        name={name}
        labelClass={labelClass}
        required={required}
      />

      <div className="relative flex w-full items-center">
        {LeadingIcon && (
          <LeadingIcon
            size={iconSize}
            className={`${iconClassName || ''} absolute left-3 top-1/2 -translate-y-1/2 transform`}
          />
        )}
        <input
          ref={ref || inputRef}
          aria-label={label}
          data-testid={testId || `${name ? name + '-' : ''}input`}
          tabIndex={0}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue || undefined}
          placeholder={placeholder}
          className={classNames(
            'text-label-medium flex h-[3.25rem] w-full flex-row items-center',
            'justify-between rounded-2xl px-4 py-1 outline-none focus:text-base md:focus:text-xs',
            inputClassName,
            {
              'bg-core-secondary': kind === 'secondary' && !disabled,
              'bg-core-tertiary': kind === 'tertiary' && !disabled,
              'bg-surface-dim': kind === 'secondary' && disabled,
              'bg-surface': kind === 'tertiary' && disabled,
              'text-on-surface-disabled': disabled,
              'pl-9': LeadingIcon,
              'focus:outline-none focus:ring-2 focus:ring-on-surface-disabled':
                !error,
              'border border-core-error focus:ring-1 focus:ring-core-error':
                !!error,
              'cursor-not-allowed': disabled,
            }
          )}
          onKeyUp={() => {
            toggleClearIcon()
          }}
          onChange={(e) => {
            toggleClearIcon()
            if (rest.onChange) rest.onChange(e)
          }}
          {...rest}
        />
        {clearIcon && shouldShowClearIcon && (
          <XSmallIcon
            onClick={() => {
              if (inputRef.current) inputRef.current.value = ''
              toggleClearIcon()
              const event = { currentTarget: inputRef.current }
              rest.onChange?.({
                target: inputRef.current,
                currentTarget: inputRef.current,
              } as ChangeEvent<HTMLInputElement>)
              rest.onKeyDown?.(event as React.KeyboardEvent<HTMLInputElement>)
              rest.onKeyUp?.(event as React.KeyboardEvent<HTMLInputElement>)
            }}
            size={20}
            className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer p-[2px]"
          />
        )}
      </div>
      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})
