export function startSkipToMainContentWatcher() {
  document.getElementById('skip-main')?.addEventListener('click', () => {
    setTimeout(() => {
      // remove the hash from the URL
      history.replaceState(null, '', ' ')
    }, 16)
  })
  document.getElementById('skip-main')?.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setTimeout(() => {
        // remove the hash from the URL
        history.replaceState(null, '', ' ')
      }, 16)
    }
  })
}
