import { Spinner } from 'components/Spinner'
import { BreakoutBarGraphVertical } from 'components/breakout/BreakoutBarGraphVertical'
import { BreakoutUserAvatarStack } from 'components/breakout/BreakoutUserAvatar'
import { EmptyState } from 'components/breakout/EmptyState'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { LegendToggle } from 'components/icons/LegendToggle'
import { Search } from 'components/icons/Search'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SummaryMethodology } from './SummaryMethodology'
import { useDialogs } from 'hooks/dialogs'
import { AssignmentRubricGroupDialog } from 'pages/instructor/dialogs/AssignmentRubricGroupDialog'
import classNames from 'classnames'

export const SummaryRubricOverall = observer(function SummaryRubricOverall() {
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  const [selectedBucket, setSelectedBucket] = useState<string>('')
  const [isSummaryVisible, setIsSummaryVisible] = useState(false)
  const { showDialog } = useDialogs()

  const handleMouseEnter = () => {
    // Show SummaryMethodology component when mouse enters
    setIsSummaryVisible(true)
  }

  const handleMouseLeave = () => {
    // Hide SummaryMethodology component when mouse leaves
    setIsSummaryVisible(false)
  }
  useEffect(() => {
    if (!cubit.assignmentsRubricsRoomStatesDataLoaded) {
      return
    }
    let selectedIndex: string = ''
    Object.keys(cubit.groupRubricHistogramWithRoomStates).map((key) => {
      const roomStates = cubit.groupRubricHistogramWithRoomStates[key]
      if (roomStates.length > 0) {
        selectedIndex = key
      }
    })
    setSelectedBucket(selectedIndex)
  }, [cubit.assignmentsRubricsRoomStatesDataLoaded, cubit])
  // show spinner if NOT cubit.professorFeedbackDataLoaded
  if (!cubit.assignmentsRubricsRoomStatesDataLoaded) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }
  if (
    !cubit.rubrics.size ||
    Array.from(cubit.rubrics.values()).every((rubrics) => !rubrics.length)
  ) {
    return (
      <EmptyState
        Icon={Search}
        text={t('instructor_assignment.results_missing')}
      />
    )
  }
  const handleBarClick = (bucket: string) => {
    setSelectedBucket(bucket)
  }
  // Function to find the largest value in quizHistogram
  const findMaxValueInHistogram = (
    quizHistogram: Record<string, number>
  ): number => {
    return Object.values(quizHistogram).reduce((previousValue, element) => {
      return previousValue > element ? previousValue : element
    }, 0)
  }

  // Function to round the max value up to the nearest 10
  const roundUpMaxValue = (max: number): number => {
    return Math.ceil(max / 10) * 10
  }

  const max = findMaxValueInHistogram(cubit.groupRubricHistogram)

  const maxRounded = roundUpMaxValue(max)

  const delta = Math.round(maxRounded / 5)
  const scale = Array.from({ length: 6 }, (_, index) => index * delta)
  const legend_toggle_outlined = <LegendToggle size={20} />

  return (
    <>
      <div className="flex items-center justify-between border-b border-border-grey pb-4">
        <div>
          <h1 className="text-title-large">
            {t('instructor_assignment.overall_rubric_assessment')}
          </h1>
        </div>
        <div
          className="relative inline-block"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleMouseEnter}
          onBlur={handleMouseLeave}
        >
          <BreakoutButton
            kind="secondary"
            size="small"
            icon={legend_toggle_outlined}
          >
            {t('instructor_assignment.methodology')}
          </BreakoutButton>
          {isSummaryVisible && (
            <div className="absolute -left-40 z-10 mt-2 w-80 rounded-lg border bg-core-tertiary p-2 shadow-lg">
              <SummaryMethodology />
            </div>
          )}
        </div>
      </div>
      <div className="flex pt-4" style={{ height: 'calc(100% - 2rem)' }}>
        <div className="h-full flex-grow flex-row">
          <BreakoutBarGraphVertical
            colorBackground="bg-light-grey"
            colorForeground="bg-light-grey-text"
            histogram={cubit.groupRubricHistogram}
            scale={scale}
            xLabel={t('instructor_assignment.overall_rubric_assessment')}
            yLabel={t('instructor_assignment.number_of_groups')}
            onPressed={handleBarClick}
            colorSelected="bg-grey-text"
            selectedBucket={selectedBucket}
          />
        </div>
        <div className="h-full w-[25%] min-w-[250px] max-w-[450px] overflow-y-auto pl-8">
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.groups')}
          </div>
          <div className="w-full">
            {Object.keys(cubit.groupRubricHistogramWithRoomStates)
              .filter((key) => key === selectedBucket)
              .map((key) => {
                const roomStates = cubit.groupRubricHistogramWithRoomStates[key]
                if (roomStates.length > 0) {
                  return roomStates.map((roomState) => {
                    const score = cubit.rubricScores.get(roomState) ?? 0
                    const groupData = cubit.assignmentGroupDataSorted.find(
                      (groupData) => groupData.roomState?.id === roomState.id
                    )
                    const results = cubit.getRubricResultsForRoom(roomState.id)

                    return (
                      <div
                        key={roomState.id}
                        className={classNames(
                          'flex flex-row items-center justify-between gap-1 border-b border-border-grey py-2',
                          {
                            'cursor-pointer': groupData !== undefined,
                          }
                        )}
                        onClick={
                          groupData
                            ? () => {
                                showDialog(() => (
                                  <AssignmentRubricGroupDialog
                                    groupData={groupData}
                                    groupResults={results}
                                  />
                                ))
                              }
                            : undefined
                        }
                      >
                        <span className="text-body-small flex-[2_2_0%]">
                          {roomState.roomStateName}
                        </span>
                        <div className="flex flex-[3_3_0%]">
                          <BreakoutUserAvatarStack
                            className="!justify-start"
                            radius={14}
                            users={roomState.users}
                            spacing={-2}
                          />
                        </div>
                        <span className="text-body-small items-end">
                          {(score * 100).toFixed(0)}%
                        </span>
                      </div>
                    )
                  })
                } else {
                  return null
                }
              })}
          </div>
        </div>
      </div>
    </>
  )
})
