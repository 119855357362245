import type {
  AssignmentGroupData,
  InstructorAssignmentCubit,
} from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { PlusIcon } from 'components/icons/Plus'
import { Search } from 'components/icons/Search'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GroupConfirmAddUserDialog } from './GroupConfirmAddUserDialog'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { GroupConfirmAddUserMultiGroup } from './GroupConfirmAddUserMultiGroup'
import { BreakoutCheckbox } from 'components/design-system/BreakoutCheckbox'

export const GroupAddUserDialog = observer(function GroupAddUserDialog({
  cubit,
  groupData,
}: {
  cubit: InstructorAssignmentCubit
  groupData: AssignmentGroupData
}) {
  // const thisGroupUsers = groupData.groupMembers
  const { showDialog } = useDialogs()
  const [chips, setChips] = useState<string[]>([])
  const [onlyShowUsersNotInGroup, setOnlyShowUsersNotInGroup] = useState(true)
  const allGroupData = cubit.assignmentGroupDataSortedWithNotInGroup
  const usersNotInGroup = cubit.usersNotInGroup
  const allUsers = cubit.section.students

  const userIdToGroupNameMap = allGroupData.reduce(
    (acc, group) => {
      group.groupMembers.forEach((userId) => {
        if (!acc[userId]) {
          acc[userId] = []
        }
        if (!group.groupName) return
        acc[userId]?.push(group.groupName || '')
      })
      return acc
    },
    {} as Record<string, string[] | undefined>
  )

  const addChip = useCallback((chip: string) => {
    setChips((prev) => [...prev, chip])
  }, [])

  const removeChip = useCallback((chip: string) => {
    setChips((prev) => prev.filter((c) => c !== chip))
  }, [])

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const name = formData.get('filter') as string
      addChip(name)
      e.currentTarget.reset()
    },
    [addChip]
  )

  const { t } = useTranslation()

  const usersToShowSorted = useMemo(() => {
    const users = onlyShowUsersNotInGroup ? usersNotInGroup : allUsers
    return users.sort((a, b) => a.fullName.localeCompare(b.fullName))
  }, [allUsers, onlyShowUsersNotInGroup, usersNotInGroup])

  return (
    <Dialog size="xs" className="!bg-core-tertiary">
      <DialogCloseButton />
      <div className="text-headline-medium mb-5 text-center">
        {t('instructor.group_user_add_student_to_group')}
      </div>
      <form onSubmit={handleSubmit}>
        <BreakoutTextInput
          autoFocus
          name="filter"
          LeadingIcon={Search}
          inputClassName="text-body-medium border font-medium placeholder-grey-text"
          kind="secondary"
          placeholder={t('instructor.group_user_add_search_by_name')}
        />
      </form>
      <BreakoutCheckbox
        value={onlyShowUsersNotInGroup}
        onChange={(value) => setOnlyShowUsersNotInGroup(value)}
        label={t('instructor.show_users_not_in_group')}
      />
      {chips.length > 0 && (
        <div className="mt-5">
          <BreakoutPill.Chips labels={chips} onClick={removeChip} />
        </div>
      )}
      <div className="mt-5">
        {usersToShowSorted.map((user) => (
          <div
            key={user.id}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.currentTarget.click()
              }
            }}
            onClick={() => {
              if (!groupData.roomState) return

              // If the user is in multiple groups, show a multi-group dialog.
              if (
                userIdToGroupNameMap[user.id] &&
                userIdToGroupNameMap[user.id]!.length > 0
              ) {
                showDialog(({ remove }) => (
                  <GroupConfirmAddUserMultiGroup
                    addUserToGroup={cubit.addUserToGroup}
                    roomState={groupData.roomState!}
                    userId={user.id}
                    onClick={remove}
                    currentGroups={userIdToGroupNameMap[user.id]!}
                  />
                ))
                return
              }

              showDialog(({ remove }) => (
                <GroupConfirmAddUserDialog
                  cubit={cubit}
                  roomState={groupData.roomState!}
                  userId={user.id}
                  onClick={remove}
                />
              ))
            }}
            className="my-2 flex cursor-pointer flex-row items-center justify-between gap-0 rounded-full p-1 px-2 hover:bg-neutral-100"
          >
            <div className="flex w-full gap-2">
              <BreakoutUserAvatar aria-hidden user={user} radius={18} />
              <div>
                <div className="text-label-medium">{user.fullName}</div>
                {userIdToGroupNameMap[user.id] && (
                  <div className="text-body-medium text-grey-text">
                    {userIdToGroupNameMap[user.id]?.join(', ')}
                  </div>
                )}
                {!userIdToGroupNameMap[user.id] && (
                  <div className="text-body-medium text-grey-text">
                    {t('instructor.group_user_not_in_group')}
                  </div>
                )}
              </div>
            </div>
            <div className="pr-2">
              <PlusIcon size={18} className="text-grey-text" />
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  )
})
