import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { PollDetailRow } from './PollDetailRow'

export const CustomPoll = ({
  answers,
  index,
  question,
}: {
  answers: RoomStateAnswer[]
  index: number
  question: SlideQuestion
}) => {
  //todo: do we even need this for the layout?
  const height = Math.max(60, 18 * question.data.answers.length + 24)

  return (
    <div
      tabIndex={0}
      className="mt-3 flex flex-row items-center rounded-2xl bg-core-tertiary px-5 py-3"
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <span className="text-title-medium mr-3">{index + 1}</span>
      <div className="flex-grow">
        <span className="text-body-medium line-clamp-3">
          {question.data.question}
        </span>
      </div>
      <div className="flex w-[250px] min-w-[250px] flex-col">
        {question.data.answers.map((answer, i) => {
          const selected = answers.filter((a) => a.data.answer === i).length
          const selectedPercentage = (selected / answers.length) * 100
          return (
            <PollDetailRow
              key={i}
              answer={answer}
              title={String.fromCharCode(i + 65)}
              selectedPercentage={selectedPercentage}
            />
          )
        })}
      </div>
    </div>
  )
}
