import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
// import { Contents, MainPane } from 'components/layout/TwoColumn'
import { SlideProjector } from 'components/SlideProjector'
import { Contents, MainPane } from 'pages/layout/TwoColumn'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { observer } from 'mobx-react-lite'
import { CasesView } from './CasesView/CasesView'
import { InstructorClassPageHeader } from './InstructorClassPageHeader'
import { StudentsView } from './StudentsView/StudentsView'
import { InstructorSectionView } from '@breakoutlearning/firebase-repository/types'
import {
  InstructorSectionCubitProvider,
  useInstructorSectionCubit,
} from 'hooks/cubits/instructorSection'
import { useRootStore } from 'hooks/rootStore'

export function InstructorClassPage({ id }: { id: string }) {
  const repository = useRepository()
  const rootStore = useRootStore()

  const instructorUserId = rootStore.impersonatedUserId
  const cubit = useCubitBuilder(
    () =>
      new InstructorSectionCubit(repository, id, {
        instructorUserId,
      }),
    [repository, id]
  )

  if (!cubit) return null

  return (
    <InstructorSectionCubitProvider value={cubit}>
      <InstructorClassPageContents key={cubit.id} />
    </InstructorSectionCubitProvider>
  )
}

function InstructorClassPageContents() {
  return (
    <MainPane className="z-50">
      <InstructorClassPageHeader />
      <Contents className="w-full md:h-full md:overflow-auto">
        <PageContent />
      </Contents>
    </MainPane>
  )
}

const PageContent = observer(function PageContent() {
  const cubit = useInstructorSectionCubit()

  return (
    <SlideProjector
      index={cubit.selectedView === InstructorSectionView.cases ? 0 : 1}
    >
      {cubit.selectedView === InstructorSectionView.cases && <CasesView />}
      {cubit.selectedView === InstructorSectionView.students && (
        <StudentsView />
      )}
    </SlideProjector>
  )
})
