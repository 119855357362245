import { BookClose } from 'components/icons/BookClose'
import { Cross } from 'components/icons/Cross'
import { Money } from 'components/icons/Money'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { Search } from 'components/icons/Search'
import { SendIcon } from 'components/icons/Send'
import { Actions, Header, Headline } from 'pages/layout/TwoColumn'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionInvitationDialog } from './SectionInvitationDialog'
import {
  InstructorSectionView,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { EndClassDialog } from '../dialogs/EndClassDialog'
import { useBreakoutUser } from 'hooks/profile'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { InvoicedClassDialog } from '../dialogs/InvoicedClassDialog'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { EditSectionDialog } from './EditSectionDialog'
import { PencilIcon } from 'components/icons/Pencil'

const ModeSwitchButton = observer(function ModeSwitchButton() {
  const { t } = useTranslation()
  const cubit = useInstructorSectionCubit()

  const selectedView = cubit.selectedView

  const section = cubit.section
  const assignmentCount =
    section && cubit.assignmentCount !== undefined
      ? `(${cubit.assignmentCount})`
      : ''
  const studentCount = section ? `(${cubit.userCount})` : ''

  return (
    <>
      {selectedView === InstructorSectionView.students && (
        <BreakoutButton
          kind="secondary"
          size="large"
          onClick={() => cubit.changeSelectedView(InstructorSectionView.cases)}
        >
          <BookClose className="mr-1 inline" size={14} />
          {t('instructor_class.cases')} {assignmentCount}
        </BreakoutButton>
      )}
      {selectedView === InstructorSectionView.cases && (
        <BreakoutButton
          kind="secondary"
          size="large"
          onClick={() =>
            cubit.changeSelectedView(InstructorSectionView.students)
          }
        >
          <PeopleGroup className="mr-1 inline" size={14} />
          {t('instructor_class.students')} {studentCount}
        </BreakoutButton>
      )}
    </>
  )
})

export const InstructorClassPageHeader = observer(
  function InstructorClassPageHeader() {
    const cubit = useInstructorSectionCubit()
    const { t } = useTranslation()
    const store = useRootStore()
    const { showDialog } = useDialogs()
    const user = useBreakoutUser()

    const section = cubit.section

    const sectionHasAssignments =
      section.isLoaded && cubit.sectionAssignmentsSorted.length > 0

    // assume completed to limit options if not loaded
    const sectionCompleted =
      !section.hasData || section.data.sectionState === SectionState.completed

    const sectionInvoiced = section.data.invoiced ?? false

    // if user doesn't own the section they can't edit it
    const readOnly =
      section.data.instructorUserId !== user.uid &&
      section.data.shareable === true

    //   const assignmentCount =
    //   section && cubit.assignmentCount !== undefined
    //     ? `(${cubit.assignmentCount})`
    //     : ''
    // const studentCount =
    //   section && cubit.userCount !== undefined ? `(${cubit.userCount || ''})` : ''

    return (
      <Header data-testid="instructor-class-header">
        <Headline
          className="text-display-medium font-display min-w-[35%] cursor-pointer"
          onClick={() => store.navigateTo('instructorClasses')}
        >
          <div className="flex w-full flex-row items-center ">
            <div className="flex-1 pr-1">
              <ChevronLeft aria-hidden size={24} />
            </div>
            <BreakoutTooltip content={section.data.className}>
              <p
                className="w-full flex-auto truncate"
                data-testid="instructor-class-header-section-name"
              >
                {section.hasData ? section.data.className : 'Loading...'}
              </p>
            </BreakoutTooltip>
          </div>
          <BreakoutTooltip content={section.data.sectionName}>
            <span
              className="text-body-medium ml-6 block w-full flex-auto truncate pr-10"
              title={section.data.sectionName}
            >
              {section.data.sectionName}
            </span>
          </BreakoutTooltip>
          <ImpersonationInfo />
        </Headline>
        <Actions>
          {!sectionCompleted &&
            sectionHasAssignments &&
            cubit.sectionAssignmentsSorted.length > 0 && (
              <BreakoutButton
                size="large"
                onClick={() => {
                  store.navigateTo('instructorLibrary', undefined, {
                    sectionId: section.id,
                  })
                }}
              >
                <Search className="mr-1 inline" size={14} />
                {t('instructor_class.explore_catalog')}
              </BreakoutButton>
            )}
          {/* Admins cannot edit sections */}
          {section && !sectionCompleted && !readOnly && !user.isCorre && (
            <BreakoutButton
              size="large"
              kind={'secondary'}
              icon={<PencilIcon size={16} />}
              onClick={() => {
                showDialog(({ remove }) => (
                  <EditSectionDialog
                    section={section}
                    updateSection={cubit.updateSection}
                    redeemPromotions={cubit.redeemPromotions}
                    onSave={remove}
                  />
                ))
              }}
            >
              {t('instructor_class.edit_class')}
            </BreakoutButton>
          )}
          <ModeSwitchButton />

          {section && !sectionCompleted && !readOnly && (
            <BreakoutButton
              kind="secondary"
              size="large"
              tooltip={t('instructor_class.invite_students_tooltip')}
              onClick={() => {
                showDialog(() => <SectionInvitationDialog cubit={cubit} />)
              }}
            >
              <SendIcon className="mr-1 inline" size={14} />
              {t('instructor_class.invite_students')}
            </BreakoutButton>
          )}
          {!sectionCompleted && !readOnly && (
            <BreakoutButton
              kind="error"
              aria-label={t('instructor_class.end_class_tooltip')}
              tooltip={t('instructor_class.end_class_tooltip')}
              onClick={() => {
                showDialog(() => <EndClassDialog cubit={cubit} />)
              }}
              icon={
                <Cross
                  className="mx-6 inline-block font-bold text-core-on-error"
                  size={18}
                  strokeWidth={3}
                />
              }
            ></BreakoutButton>
          )}

          {user.isCorre && (
            <FloatingIconActionButton
              kind="secondary"
              className="z-10 flex justify-end"
              aria-label={t('buttons.admin_actions')}
              menuBottom={true}
              Icon={AdminPanelSettingsIcon}
              actions={[
                {
                  text: sectionInvoiced
                    ? t('instructor_class.invoiced_tooltip')
                    : t('instructor_class.make_invoiced_tooltip'),
                  Icon: Money,
                  onClick: () => {
                    if (!sectionInvoiced)
                      showDialog(() => (
                        <InvoicedClassDialog
                          invoicedSection={cubit.invoicedSection}
                        />
                      ))
                  },
                },
              ]}
            />
          )}
        </Actions>
      </Header>
    )
  }
)
