import { RubricType } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { Spinner } from 'components/Spinner'
import { EmptyState } from 'components/breakout/EmptyState'
import { Search } from 'components/icons/Search'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BloomsRubricChart } from '../rubric_components/BloomsRubricChart'
import { PassFailRubricChart } from '../rubric_components/PassFailRubricChart.'

export const InstructorAssignmentResultsRubric = observer(
  function InstructorAssignmentResultsRubric() {
    const cubit = useInstructorAssignmentCubit()
    const { t } = useTranslation()

    const rubricsList = useMemo(
      () => Array.from(cubit.rubrics.entries()),
      [cubit.rubrics]
    )

    if (cubit.rubricResultsDataIsLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )
    }

    if (
      !rubricsList.length ||
      rubricsList.every(([, rubricResults]) => !rubricResults.length)
    ) {
      return (
        <EmptyState
          Icon={Search}
          text={t('instructor_assignment.results_missing')}
        />
      )
    }

    return (
      <div className="flex h-full w-full flex-col" role="list">
        {rubricsList.map(([slideRubric, rubricResults]) => {
          return slideRubric.rubricType === RubricType.passFail ? (
            <PassFailRubricChart
              key={slideRubric.id}
              extraClasses="mr-3 mt-3 h-[112px] w-full rounded-3xl bg-core-tertiary px-[20px] py-4"
              results={rubricResults}
              rubric={slideRubric}
              roomStates={cubit.roomStates.models}
              section={cubit.section}
              usersMap={cubit.usersMap}
            />
          ) : (
            <BloomsRubricChart
              key={slideRubric.id}
              extraClasses="mr-3 mt-3 h-[170px] w-full rounded-3xl bg-core-tertiary px-[20px] py-4"
              results={rubricResults}
              rubric={slideRubric}
              roomStates={cubit.roomStates.models}
              section={cubit.section}
              usersMap={cubit.usersMap}
            />
          )
        })}
      </div>
    )
  }
)
