import { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { getCoefficientOfVariation } from '@breakoutlearning/firebase-repository/util'
import classNames from 'classnames'
import { Spinner } from 'components/Spinner'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { ChatErrorIcon } from 'components/icons/ChatError'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useWaitFor } from './use-wait-for'

export const GroupBalanceScore = observer(function GroupBalanceScore() {
  const cubit = useMeetingResultsCubit()
  const { t } = useTranslation()
  const waitedFor3Min = useWaitFor(3)

  // get talk time for all users
  const { chartData, upperBound, lowerBound, averageTalkTime, balanced } =
    useMemo(() => {
      const {
        sessionResults: { groupAverageTalkTimeMinutes },
        engagementByUserId,
      } = cubit

      const talkTimeMinutesByUserID: Record<string, number> = {}
      let highestTalkTime = 0
      let lowestTalkTime = Infinity

      // generate talk times for each uid
      for (const [userId, { talkTimeMinutes }] of Object.entries(
        engagementByUserId.data
      )) {
        talkTimeMinutesByUserID[userId] = talkTimeMinutes
        if (talkTimeMinutes > highestTalkTime) highestTalkTime = talkTimeMinutes
        if (talkTimeMinutes < lowestTalkTime) lowestTalkTime = talkTimeMinutes
      }

      const graphUserIds = Object.keys(talkTimeMinutesByUserID)

      // get name labels for each user
      // if there are duplicate first names, add last initial
      // if duplicate first names with last initial, show full name
      const seenFirstNames: Set<string> = new Set()
      const seenFirstNamesWithInitial: Set<string> = new Set()
      const duplicateFirstNames: Array<string> = []
      const duplicateFirstNamesWithInitial: Array<string> = []

      const users: Record<
        string,
        { user: PublicUser; firstNameWithInitial: string }
      > = graphUserIds.reduce(
        (
          acc: Record<
            string,
            { user: PublicUser; firstNameWithInitial: string }
          >,
          userId
        ) => {
          const user =
            cubit.meetingCubit.users.find((u) => u.id === userId) ||
            PublicUser.empty(cubit.repository)
          const firstName = user.data.firstName
          const firstNameWithInitial =
            user.data.firstName + ' ' + user.data.lastName
              ? user.data.lastName[0]
              : ' '
          acc[userId] = { user, firstNameWithInitial }
          if (seenFirstNames.has(firstName)) duplicateFirstNames.push(firstName)
          if (seenFirstNamesWithInitial.has(firstNameWithInitial))
            duplicateFirstNamesWithInitial.push(firstNameWithInitial)
          seenFirstNames.add(firstName)
          seenFirstNamesWithInitial.add(firstNameWithInitial)
          return acc
        },
        {}
      )

      // generate record with key UID and value talk time / name label
      const talkTimeAndNameLabel: Array<{
        uid: string
        talkTimeMinutes: number
        nameLabel: string
      }> = graphUserIds.map((uid) => {
        const nameLabel = (() => {
          const user = users[uid].user || PublicUser.empty(cubit.repository)
          const firstName = user.data?.firstName || ''
          const firstNameWithInitial = users[uid].firstNameWithInitial ?? ''

          if (
            duplicateFirstNames.includes(firstName) &&
            duplicateFirstNamesWithInitial.includes(firstNameWithInitial)
          ) {
            return user.fullName
          } else if (duplicateFirstNames.includes(firstName)) {
            return firstNameWithInitial
          } else {
            return firstName
          }
        })()
        return {
          uid,
          talkTimeMinutes: talkTimeMinutesByUserID[uid] ?? 0,
          nameLabel,
        }
      })

      // get coefficient of variation and check against threshold from feature flags to see if discussion was balanced
      // or asymmetric
      const coefficientOfVariation = getCoefficientOfVariation(
        talkTimeAndNameLabel.map((x) => x.talkTimeMinutes)
      )
      const CVthreshold =
        cubit.repository.featureFlags.data.sessionResultsGroupBalanceCoefficient
      const balanced = coefficientOfVariation < CVthreshold

      // upper bound should be 2x the average unless the highest talk time is higher
      // lower bound should be 0 unless the lowest talk is 0, then lower bound should be negative 1
      // to render bars even if user had 0 talk minutes
      //todo(ashold12): if not dynamic enough use larger of the following median OR average to calc upper bound
      const upperBound = Math.max(
        groupAverageTalkTimeMinutes * 2,
        highestTalkTime
      )
      const lowerBound = lowestTalkTime > 0 ? 0 : -1
      const averageTalkTime = groupAverageTalkTimeMinutes

      return {
        chartData: talkTimeAndNameLabel,
        averageTalkTime,
        upperBound,
        lowerBound,
        balanced,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      cubit.sessionResults,
      cubit.engagementByUserId,
      cubit.repository,
      cubit,
      cubit.meetingCubit.usersLoading,
      cubit.meetingCubit,
      cubit.repository.featureFlags.data.sessionResultsGroupBalanceCoefficient,
    ])

  if (
    cubit.engagementStatus === 'failure' ||
    (cubit.engagementStatus === 'pending' && waitedFor3Min)
  ) {
    return <EmptyOrLoading state="empty" />
  }
  if (cubit.engagementStatus === 'pending') {
    return <EmptyOrLoading state="loading" />
  }

  return (
    <>
      <div className="text-title-large">{t('meeting.group_balance_score')}</div>
      <div className="text-body-medium">
        <Trans
          i18nKey={`meeting.${balanced ? 'group_balance_score_balanced_description' : 'group_balance_score_asymmetric_description'}`}
          components={{ bold: <strong /> }}
        />
      </div>
      {/* chart div (todo: remove red border)*/}
      {/* note: maybe add py-[9px] back to chart, removed as seemed like excessive space */}
      <div className="mt-3 flex h-full min-h-[252px] w-full flex-row">
        {/*left legend*/}
        <div
          className="text-body-small rotate-180 p-1 text-center"
          style={{
            writingMode: 'vertical-rl',
          }}
        >
          {t('meeting.results.individual_talk_time')}
        </div>
        {/*  chart content */}
        <div className="flex w-full flex-col gap-4">
          {/* bars*/}
          <div className="relative flex flex-grow flex-row">
            {chartData.map(({ talkTimeMinutes, uid, nameLabel }) => {
              const talkTimeMinutesRounded =
                Math.round(talkTimeMinutes * 10) / 10
              const barHeight =
                ((talkTimeMinutesRounded - lowerBound) /
                  (upperBound - lowerBound)) *
                100
              // round talk time minutes to 1 decimal
              return (
                <div
                  key={uid + ' bar'}
                  data-testid={`group-balance-score-bar-${uid}`}
                  aria-label={`${nameLabel} (${talkTimeMinutesRounded} ${t('meeting.results.mins')})`}
                  className="flex h-full flex-1 flex-col justify-end px-[1px]"
                >
                  {/* colored potions
                    todo(ashold12): opacity is too dim at 10% from figma
                   */}
                  <BreakoutTooltip
                    content={`${nameLabel} (${talkTimeMinutesRounded} ${t('meeting.results.mins')})`}
                  >
                    <div
                      className={classNames(
                        'cursor-pointer rounded-lg bg-fixed-accent-color',
                        {
                          'opacity-40': cubit.userId !== uid,
                        }
                      )}
                      style={{
                        // because border is 2px we subtract half height of border
                        // so the border lands center on the average pos
                        height: `calc(${barHeight}% - 1px)`,
                      }}
                    ></div>
                  </BreakoutTooltip>
                </div>
              )
            })}
            {/* overlay */}
            <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col">
              <div className="relative flex-grow">
                <span className="text-label-small absolute bottom-0 w-full text-center">
                  {t('meeting.results.average_talk_time')}
                </span>
              </div>
              <div
                className="border-t-2 border-dashed border-fixed-grey"
                style={{
                  height: `${((averageTalkTime - lowerBound) / (upperBound - lowerBound)) * 100}%`,
                }}
              ></div>
            </div>
          </div>
          {/* name labels*/}
          <div className="flex flex-row">
            {chartData.map(({ nameLabel, uid }) => {
              return (
                <div
                  key={`${uid}-name`}
                  className="text-body-small line-clamp-1 flex-1 text-center"
                >
                  {nameLabel}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
})

const EmptyOrLoading = observer(function EmptyOrLoading({
  state,
}: {
  state: 'loading' | 'empty'
}) {
  const { t } = useTranslation()
  const Icon =
    state === 'loading' ? <Spinner size={1.75} /> : <ChatErrorIcon size={28} />
  const text =
    state === 'loading'
      ? t('meeting.results.group_balance_loading')
      : t('meeting.results.group_balance_empty')

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-1 text-center">
      {Icon}
      <h2 className="text-title-large">{t('meeting.group_balance_score')}</h2>
      <span className="text-body-medium">{text}</span>
    </div>
  )
})
