import type { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { DialogContext } from 'components/contexts/dialogs'
import { Dialog } from 'components/dialogs/Dialog'
import { Close } from 'components/icons/Close'
import { Enlarge } from 'components/icons/Enlarge'
import {
  InstructorAssignmentCubitProvider,
  useInstructorAssignmentCubit,
} from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SummaryQuizAssessment } from './summary/SummaryQuizAssessment'
import { SummaryQuestions } from './summary/SummaryQuestions'
import { SummaryRubricOverall } from './summary/SummaryRubricOverall'
import { SummaryRubricAssessment } from './summary/SummaryRubricAssessment'
import { SummaryPolls } from './summary/SummaryPolls'
import { EmptyState } from 'components/breakout/EmptyState'
import { SparklesIcon } from 'components/icons/Sparkles'

export const SummaryView = observer(function SummaryView({
  cubit,
  removeSelfDialog = () => {},
  isDialog = false,
}: {
  cubit: InstructorAssignmentCubit
  removeSelfDialog?: () => void
  isDialog?: boolean
}) {
  // Because SummaryView can operate both as a dialog and as a full page view
  // we re-use the provider so that we don't have to prop drill the cubit
  return (
    <InstructorAssignmentCubitProvider value={cubit}>
      {cubit.assignmentProgress.percentageCompleted === 0 ? (
        <EmptyView />
      ) : (
        <div className="h-full w-full overflow-hidden">
          <div className="mr-5 h-full w-full bg-surface px-10 py-8">
            <div className="pb-6">
              <SummaryTitleBar
                removeSelfDialog={removeSelfDialog}
                isDialog={isDialog}
              />
            </div>
            <div
              className="relative w-full overflow-auto"
              style={{ height: 'calc(100% - 4rem)' }} // full height mins the title area
            >
              <div className="flex w-full flex-col gap-3 xl:flex-row">
                <div
                  className="h-[485px] flex-1 rounded-2xl bg-core-tertiary p-8"
                  tabIndex={0}
                >
                  <SummaryQuizAssessment />
                </div>
                <div
                  className="h-[485px] flex-1 rounded-2xl bg-core-tertiary p-8"
                  tabIndex={0}
                >
                  <SummaryQuestions />
                </div>
              </div>
              <div className="flex w-full gap-3 pt-3">
                <div
                  className="h-[485px] flex-1 rounded-2xl bg-core-tertiary p-8"
                  tabIndex={0}
                >
                  <SummaryRubricOverall />
                </div>
              </div>
              <div className="flex w-full gap-3 pt-3">
                <div
                  className="flex-grow rounded-2xl bg-core-tertiary p-8"
                  tabIndex={0}
                >
                  <SummaryRubricAssessment />
                </div>
              </div>
              <div className="flex w-full gap-3 pt-3">
                <div
                  className="flex-grow rounded-2xl bg-core-tertiary p-8"
                  tabIndex={0}
                >
                  <SummaryPolls />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </InstructorAssignmentCubitProvider>
  )
})

const EmptyView = observer(function EmptyView() {
  const { t } = useTranslation()
  return (
    <EmptyState
      Icon={SparklesIcon}
      text={t('instructor_assignment.sessions_summary_missing')}
    />
  )
})

const SummaryTitleBar = observer(function SummaryTitleBar({
  isDialog = false,
  removeSelfDialog = () => {},
}: {
  isDialog?: boolean
  removeSelfDialog?: () => void
}) {
  const cubit = useInstructorAssignmentCubit()
  const {
    assignmentProgress: { studentCount, totalGroups, percentageCompleted },
  } = cubit
  const dialog = useContext(DialogContext)

  const showDialogSelf = useCallback(() => {
    if (!dialog) return
    dialog.showDialog(({ remove }) => (
      <Dialog
        size="xl"
        className="h-[95%] w-[95%]"
        innerClassName="!p-0 flex h-full"
      >
        <div className="h-full w-full">
          <SummaryView cubit={cubit} removeSelfDialog={remove} isDialog />
        </div>
      </Dialog>
    ))
  }, [dialog, cubit])
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-between">
      <div className="text-headline-large">
        {t('instructor_assignment.assignment_summary')}
      </div>
      {/* Content on the right */}
      <div className="flex space-x-4">
        <div className="flex items-center">
          <span className="text-title-large pr-1">{studentCount}</span>
          <span className="text-body-medium text-grey-text">
            {t('instructor_assignment.students_signed_up')}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-title-large pr-1">{totalGroups}</span>
          <span className="text-body-medium text-grey-text">
            {t('instructor_assignment.groups_created')}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-title-large pr-1 text-breakout-dark-green">
            {percentageCompleted.toFixed(0)}%
          </span>
          <span className="text-body-medium text-grey-text">
            {t('instructor_assignment.completion')}
          </span>
        </div>
        <div className="flex items-center">
          <button
            aria-label={
              isDialog
                ? t('instructor_assignment.close_summary')
                : t('instructor_assignment.expand_summary')
            }
            className="relative ml-auto"
            onClick={isDialog ? removeSelfDialog : showDialogSelf}
          >
            {!isDialog ? <Enlarge size={24} /> : <Close size={24} />}
          </button>
        </div>
      </div>
    </div>
  )
})
