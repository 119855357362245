import { useCubitBuilder } from 'hooks/cubits'
import { observer } from 'mobx-react-lite'
import { InstructorOrganizationsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorOrganizationsCubit'
import { useRepository } from 'hooks/auth'
import { Contents, Header, Headline, MainPane } from 'pages/layout/TwoColumn'
import { Spinner } from 'components/Spinner'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { Dialog } from 'components/dialogs/Dialog'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { useEffect } from 'react'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { LinkIcon } from 'components/icons/Link'
import { PlusIcon } from 'components/icons/Plus'
import { ConfirmationDialog } from 'components/ConfirmationDialog'

export const InstructorOrganizations = observer(
  function InstructorOrganizations() {
    const repository = useRepository()

    const cubit = useCubitBuilder(
      () => new InstructorOrganizationsCubit(repository),
      [repository]
    )

    return (
      <MainPane>
        <Header>
          <Headline>Organizations</Headline>
        </Header>
        <Contents className="h-full overflow-hidden">
          <PageContents cubit={cubit} />
        </Contents>
      </MainPane>
    )
  }
)

const PageContents = observer(function PageContents({
  cubit,
}: {
  cubit: InstructorOrganizationsCubit
}) {
  if (!cubit.loaded) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      <OrganizationsWhereAdmin cubit={cubit} />
      <OrganizationsWhereInstructor cubit={cubit} />
    </div>
  )
})

const OrganizationsWhereAdmin = observer(function OrganizationsWhereAdmin({
  cubit,
}: {
  cubit: InstructorOrganizationsCubit
}) {
  const repository = useRepository()
  if (!cubit.adminOrgs.length) return null
  return (
    <div className="mb-5 flex flex-col gap-5">
      <h2 className="text-headline-medium">Admin Organizations</h2>
      <ul>
        {cubit.adminOrgs.models.map((org) => {
          const instructorIds = cubit.instructorIdsByOrgId.get(org.id) || []
          return (
            <li
              className="mb-2 w-full rounded-xl bg-surface p-4"
              key={`${org.id}-admin`}
            >
              <div className="flex flex-row justify-between">
                <span className="text-headline-small">{`${org.data.organizationName}: ${org.data.organizationInstitution}`}</span>
                <div className="flex flex-row gap-1">
                  <CreateOrganizationInvitationButton
                    type="admin"
                    cubit={cubit}
                    organizationId={org.id}
                  />
                  <CreateOrganizationInvitationButton
                    type="instructor"
                    cubit={cubit}
                    organizationId={org.id}
                  />
                </div>
              </div>
              <ul>
                {instructorIds.map((id) => {
                  const instructor = repository.userStore.getUser(id)
                  return (
                    <li key={id}>
                      <div className="ml-4 flex flex-row items-center gap-1">
                        <BreakoutUserAvatar user={instructor} radius={10} />
                        <span>{instructor.fullName}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
})

const OrganizationsWhereInstructor = observer(
  function OrganizationsWhereInstructor({
    cubit,
  }: {
    cubit: InstructorOrganizationsCubit
  }) {
    if (!cubit.instructorOrgs.length) return null
    return (
      <div className="flex flex-col gap-5">
        <h2 className="text-headline-medium">Instructor Organizations</h2>
        <ul>
          {cubit.instructorOrgs.models.map((org) => {
            return (
              <li
                className="mb-2 w-full rounded-xl bg-surface p-4"
                key={`${org.id}-admin`}
              >
                <span className="text-headline-small">{`${org.data.organizationName}: ${org.data.organizationInstitution}`}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
)

// const RoleSelectionDialog = observer(function RoleSelectionDialog({
//   onRoleSelect,
//   type,
// }: {
//   onRoleSelect: (role: UserProfileRole.ta | UserProfileRole.instructor) => void
//   type: 'admin' | 'instructor'
// }) {
//   return (
//     <Dialog size="sm" innerClassName="flex">
//       <DialogCloseButton />
//       <div className="text-headline-large flex w-full flex-col gap-3 text-center">
//         <h2 className="text-headline-medium"> Invite to Organization</h2>
//         <span className="text-body-large">
//           Select role to invite the user as
//         </span>
//         <div className="flex flex-grow" />
//         {type === 'instructor' && (
//           <BreakoutButton
//             fullWidth
//             size="large"
//             onClick={() => onRoleSelect(UserProfileRole.ta)}
//           >
//             Teaching Assistant (TA)
//           </BreakoutButton>
//         )}
//         <BreakoutButton
//           fullWidth
//           size="large"
//           onClick={() => onRoleSelect(UserProfileRole.instructor)}
//         >
//           Instructor
//         </BreakoutButton>
//       </div>
//     </Dialog>
//   )
// })

const DisplayInvitationDialog = ({
  invitationUrl,
}: {
  invitationUrl: string
}) => {
  const { t } = useTranslation()
  const { clearAllDialogs } = useDialogs()

  useEffect(() => {
    navigator.clipboard.writeText(invitationUrl)
  }, [invitationUrl])

  return (
    <Dialog
      size="xs"
      key="dialog-show-url"
      innerClassName="flex flex-col justify-center"
      className="!bg-surface"
    >
      <DialogCloseButton />

      <h3 className="text-center text-xl font-semibold">
        {t('admin_catalogs.link_copied_clipboard')}
      </h3>
      <BreakoutTextInput
        data-testid="-organization-invitation-url"
        className="mt-6"
        inputClassName="cursor-text w-full"
        iconClassName="cursor-pointer"
        LeadingIcon={({ ...props }) => (
          <LinkIcon
            onClick={() => {
              navigator.clipboard.writeText(invitationUrl)
              clearAllDialogs()
            }}
            {...props}
          />
        )}
        value={invitationUrl}
        readOnly
      />
    </Dialog>
  )
}

const CreateOrganizationInvitationButton = observer(
  function CreateInvitationButton({
    type,
    cubit,
    organizationId,
  }: {
    type: 'admin' | 'instructor'
    cubit: InstructorOrganizationsCubit
    organizationId: string
  }) {
    const { showDialog, clearAllDialogs } = useDialogs()
    const { t } = useTranslation()

    return (
      <BreakoutButton
        icon={<PlusIcon size={14} />}
        size="small"
        kind="tertiary"
        onClick={() => {
          const confirmationCopy = t(
            `instructor_organizations.${type === 'admin' ? 'admin_invite_warning' : 'instructor_invite_warning'}`
          )
          showDialog(({ remove }) => (
            <ConfirmationDialog
              text="Warning"
              subtitle={confirmationCopy}
              dismiss={() => remove()}
              onConfirm={async () => {
                try {
                  const invitationId = await cubit.createOrganizationInvitation(
                    {
                      organizationId,
                      isAdminInvitation: type === 'admin',
                    }
                  )

                  // copy to clipboard
                  const invitationURL = `${window.location.origin}/invitation/${invitationId}`
                  showDialog(() => (
                    <DisplayInvitationDialog invitationUrl={invitationURL} />
                  ))
                } catch (e) {
                  clearAllDialogs()
                  toast.error('Invitation creation failure')
                  throw e
                }
              }}
            />
          ))
        }}
      >
        Invite {type === 'admin' ? 'Admin' : 'Instructor'}
      </BreakoutButton>
    )
  }
)
