import { Spinner } from 'components/Spinner'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { EmptyState } from 'components/breakout/EmptyState'
import { ChatQuestionIcon } from 'components/icons/ChatQuestion'
import { ChevronRight } from 'components/icons/ChevronRight'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const SummaryQuestions = observer(function SummaryQuestions() {
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  // show spinner if NOT cubit.professorFeedbackDataLoaded
  if (!cubit.professorFeedbackDataLoaded) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }
  if (cubit.roomStateProfessorFeedbacks.length === 0) {
    return (
      <EmptyState
        Icon={ChatQuestionIcon}
        text={t('instructor_assignment.questions_missing')}
      />
    )
  }

  return (
    <>
      <div className="flex items-center justify-between border-b border-border-grey pb-4">
        <div>
          <h1 className="text-title-large">
            {t('instructor_assignment.questions')}
          </h1>
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.n_questions_captured', {
              count: cubit.professorFeedbackQuestionsCount,
            })}
          </div>
        </div>
        <div
          className="text-body-medium flex cursor-pointer items-center justify-between text-grey-text"
          onClick={() => {
            cubit.changeResultsTab('questions')
            cubit.changeTab('results')
          }}
        >
          {t('instructor_assignment.view_details')}
          <span className="text-grey-text">
            <ChevronRight size={14} />
          </span>
        </div>
      </div>
      <div className="max-h-80 overflow-y-auto" tabIndex={0}>
        {/* map over cubit.professorFeedbackSummaryList */}
        {cubit.professorFeedbackSummaryList.map((feedback, index) => {
          const user = cubit.getSectionUser(feedback.data.userId)
          return (
            <div key={index} className="border-b border-border-grey py-2">
              <div className="flex items-center">
                <BreakoutUserAvatar user={user} radius={12} />
                <span className="text-label-medium ml-2 h-full">
                  {user.fullName}
                </span>
              </div>
              <p className="text-body-medium pl-8">
                {feedback.data.professorFeedback![0]}
              </p>
            </div>
          )
        })}
      </div>
    </>
  )
})
