import { MeetingCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingCubit'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { MeetingContextProvider } from 'hooks/meeting'
import { useObjectUrlCacheManager } from 'hooks/objectUrl'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useRouter } from 'hooks/router'
import { reaction } from 'mobx'
import { lazy, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
const MeetingPage = lazy(() => import('./MeetingPage'))

export function MeetingEntrypoint() {
  const rootStore = useRootStore()
  const router = useRouter()
  const params = router.params
  const roomId =
    params?.roomId && typeof params.roomId === 'string' ? params.roomId : null
  const currentUser = useBreakoutUser()
  const repository = useRepository()

  const [meeting, setMeeting] = useState<MeetingCubit | null>(null)

  const objectUrlCache = useObjectUrlCacheManager()
  const { t } = useTranslation()

  useEffect(() => {
    if (!roomId) return
    const meeting = new MeetingCubit(currentUser, repository, roomId)

    // This is a hot reload cleanup - it cancels the timer when the component is
    // hot reload happens
    if (import.meta.hot) {
      import.meta.hot.dispose(() => {
        // dispose all listeners and timers - if this component was rebuilt, it means
        // the old meeting is getting thrown out (but because it's a hot reload, the unmount method)
        // will not be called
        meeting.dispose()
      })
    }

    meeting.initialize()
    setMeeting(meeting)

    return () => {
      meeting?.dispose()
      objectUrlCache.clear('meeting')
    }
  }, [currentUser, repository, roomId, objectUrlCache])

  useEffect(() => {
    if (!meeting) return
    return reaction(
      () => meeting.roomFailedToLoad,
      (failed) => {
        if (failed) {
          toast.error(t('meeting.room_failed_to_load'))
          rootStore.navigateTo('home')
        }
      },
      { fireImmediately: true }
    )
  }, [meeting, rootStore, t])

  const meetingContext = useMemo(() => {
    if (!meeting) return null
    return { meeting: meeting }
  }, [meeting])

  if (!meetingContext)
    return (
      <div className="absolute box-border h-full w-full" id="main">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </div>
    )

  const isReady = meetingContext

  return (
    <div className="absolute box-border h-full w-full" id="main">
      {!isReady && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {isReady && (
        <MeetingContextProvider value={meetingContext}>
          <MeetingPage />
        </MeetingContextProvider>
      )}
    </div>
  )
}
