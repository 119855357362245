import React from 'react'

type IconProps = {
  size: number
}

type EmptyStateProps = {
  Icon: React.ComponentType<IconProps>
  text: string
}

export const EmptyState = ({ Icon, text }: EmptyStateProps) => {
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-7"
      tabIndex={0}
    >
      <Icon size={50} /> {/* Supply the size prop here */}
      <p className="text-title-medium text-center">{text}</p>
    </div>
  )
}
